require('./bootstrap');

import Alpine from 'alpinejs';
window.Alpine = Alpine;
queueMicrotask(() => {
    Alpine.start()
});

var hscroll;

//*
window.onscroll = function() {
    //console.log(window.innerWidth);
    if(window.innerWidth < 768)
        scrollFunctionM()
    else
        scrollFunction()
};
//*/




function scrollFunction() {
    if ((document.documentElement.scrollTop > 115)) {
        var hscroll = document.documentElement.scrollHeight - window.innerHeight - document.getElementById("main-header").clientHeight;
        if(hscroll > 20) {
            document.body.classList.add("sticky");
        } else {
            //document.body.classList.remove("sticky");
        }
    } else if ((document.documentElement.scrollTop < 20)) {
        document.body.classList.remove("sticky");
    }
}
function scrollFunctionM() {
    if ((document.documentElement.scrollTop > 115)) {
        var hscroll = document.documentElement.scrollHeight - window.innerHeight - document.getElementById("main-header").clientHeight;
        if(hscroll > 20) {
            document.body.classList.add("sticky");
        } else {
            //document.body.classList.remove("sticky");
        }
    } else if ((document.documentElement.scrollTop < 20)) {
        document.body.classList.remove("sticky");
    }
}

if ("onhashchange" in window) { // event supported?
    window.onhashchange = function () {
        hashChanged(window.location.hash);
    }
}
else { // event not supported:
    var storedHash = window.location.hash;
    window.setInterval(function () {
        if (window.location.hash != storedHash) {
            storedHash = window.location.hash;
            hashChanged(storedHash);
        }
    }, 100);
}
function hashChanged(hash)
{
    if(hash=='#categorii') {
        //document.getElementById('invisibleShowOnlyCategories').click();
    }
}
